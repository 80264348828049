import { GraphQLResult } from '@aws-amplify/api-graphql'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth'
import { API as AmplifyAPI } from 'aws-amplify'
import { unref } from 'vue'

import { useAuth0 } from '@/composables/useAuth0'
import type {
  ApiGetOptions,
  AvailableOperations,
  Operation,
  OperationUserTypes,
} from '@/domain/API'

export const getAccessToken = async (): Promise<string | undefined> => {
  const auth0 = useAuth0()

  try {
    return await auth0.getAccessTokenSilently()
  } catch (error) {
    await auth0.loginWithRedirect({
      appState: { target: window.location.pathname },
    })
  }
}

/**
 * @param root0
 * @param root0.query
 * @param root0.variables
 */
export async function apiRequest({
  query,
  variables,
}: {
  query: string
  variables: Record<string, any>
}): Promise<GraphQLResult<any>> {
  return AmplifyAPI.graphql({
    authMode: GRAPHQL_AUTH_MODE.OPENID_CONNECT,
    authToken: await getAccessToken(),
    query,
    variables,
  })
}

/**
 * @param root0
 * @param root0.query
 * @param root0.variables
 */
export async function apiRequestAll({
  query,
  variables,
}: {
  query: string
  variables: Record<string, any>
}) {
  let results: any[] = []
  let nextToken
  do {
    const response: GraphQLResult<any> = await apiRequest({
      query,
      variables: { ...variables, nextToken },
    })
    const key = Object.keys(response.data)[0]
    const items = response.data[key].items
    const newNextToken = response.data[key].nextToken

    results = [...results, ...items]
    nextToken = newNextToken
  } while (nextToken)

  return results
}

export class RestApi {
  functionName: string
  omitAuthHeader: boolean

  constructor({ anonymous }: { anonymous: boolean } = { anonymous: false }) {
    this.functionName = 'manyvetsapi'
    this.omitAuthHeader = anonymous
  }

  async prepareRequestOptions({ headers, ...args }: Record<string, any>) {
    if (this.omitAuthHeader && !headers) {
      return { ...args }
    }

    const accessToken = await getAccessToken()

    const auth0 = useAuth0()

    return {
      headers: {
        Authorization: `${
          auth0.isAuthenticated.value ? 'Bearer ' : ''
        }${accessToken}`,
        ...headers,
      },
      ...args,
    }
  }

  async post(
    path: string,
    requestOptions: {
      [key: string]: any
    } = {}
  ) {
    return AmplifyAPI.post(
      this.functionName,
      path,
      await this.prepareRequestOptions(requestOptions)
    )
  }

  async get(
    path: string,
    requestOptions: {
      [key: string]: any
    } = {}
  ) {
    return AmplifyAPI.get(
      this.functionName,
      path,
      await this.prepareRequestOptions(requestOptions)
    )
  }

  async patch(
    path: string,
    requestOptions: {
      [key: string]: any
    } = {}
  ) {
    return AmplifyAPI.patch(
      this.functionName,
      path,
      await this.prepareRequestOptions(requestOptions)
    )
  }
}

export class SimpleRestApi {
  async prepareRequestOptions({ headers, ...args }: Record<string, any>) {
    const accessToken = await getAccessToken()

    const auth0 = useAuth0()

    return {
      headers: {
        Authorization: `${
          auth0.isAuthenticated.value ? 'Bearer ' : ''
        }${accessToken}`,
        ...headers,
      },
      ...args,
    }
  }

  async get(
    path: string,
    requestOptions: {
      [key: string]: any
    } = {},
    queryParams: {
      [key: string]: any
    } = {}
  ) {
    const preparedRequestOptions =
      await this.prepareRequestOptions(requestOptions)

    const url = new URL(
      `${import.meta.env.VITE_CLAIMS_ORCHESTRATOR_URI}${path}`
    )

    Object.entries(queryParams).forEach(([key, value]) => {
      url.searchParams.set(key, value)
    })

    return fetch(url.toString(), {
      method: 'GET',
      ...preparedRequestOptions,
    })
  }

  async post(
    path: string,
    requestOptions: {
      [key: string]: any
    } = {}
  ) {
    const preparedRequestOptions = await this.prepareRequestOptions({
      headers: {
        'Content-Type': 'application/json',
      },
      ...requestOptions,
    })

    const url = import.meta.env.VITE_CLAIMS_ORCHESTRATOR_URI

    return fetch(`${url}${path}`, {
      method: 'POST',
      ...preparedRequestOptions,
    })
  }

  async patch(
    path: string,
    requestOptions: {
      [key: string]: any
    } = {}
  ) {
    const preparedRequestOptions = await this.prepareRequestOptions({
      headers: {
        'Content-Type': 'application/json',
      },
      ...requestOptions,
    })

    const url = import.meta.env.VITE_CLAIMS_ORCHESTRATOR_URI

    return fetch(`${url}${path}`, {
      method: 'PATCH',
      ...preparedRequestOptions,
    })
  }
}

export class API {
  auth0
  userType: OperationUserTypes

  constructor() {
    this.auth0 = useAuth0()
    this.userType = this.getUserType()
  }

  async prepareRequestOptions(additionalHeaders = {}) {
    let accessToken

    // Doing this check allows us to make unauthenticated requests to the endpoints that need to be open (doc requests). As `getAccessToken` does an authentication check and redirect if the access token has expired
    if (this.userType !== 'unauthenticated') {
      accessToken = await getAccessToken()
    }

    return {
      headers: {
        ...(this.auth0.isAuthenticated.value &&
          this.userType !== 'unauthenticated' && {
            Authorization: `Bearer ${accessToken}`,
          }),
        ...additionalHeaders,
      },
    }
  }

  getOperation = (operation: AvailableOperations) => {
    const requestedOperation = operations[operation]

    const requestedUserOperation = requestedOperation.find(
      (item) => item.user_type === this.userType
    )

    if (!requestedUserOperation) {
      throw new Error(
        `Unable to find requested operation ${operation} for ${this.userType}`
      )
    }

    if (this.userType === 'vet') {
      const currentPracticeId = this.auth0.getCurrentPracticeId()

      if (!currentPracticeId) {
        throw new Error('No practice id found for current user')
      }

      const newUrl = requestedUserOperation.url.replace(
        '${vet_practice_id}',
        currentPracticeId
      )
      requestedUserOperation.url = newUrl
    }

    return requestedUserOperation
  }

  getUserType = (): OperationUserTypes => {
    if (!unref(this.auth0.isAuthenticated)) {
      return 'unauthenticated'
    }

    if (this.auth0.getUserRole() === 'PracticeManager') {
      return 'vet'
    }

    if (['SysAdmin', 'Handler'].includes(this.auth0.getUserRole())) {
      return 'staff'
    }

    throw new Error('Unknown user role')
  }

  async get(
    operation: AvailableOperations,
    requestOptions: ApiGetOptions = {}
  ) {
    const operationItem = this.getOperation(operation)

    if (!operationItem) {
      throw new Error('Unknown operation')
    }

    let path = operationItem.url
    if (requestOptions.pathOptions) {
      const pathOptions = requestOptions.pathOptions
      const pathKeys = Object.keys(pathOptions)
      pathKeys.forEach((key: string) => {
        path = path.replace(`\${${key}}`, pathOptions[key])
      })
    }

    const url = new URL(
      `${import.meta.env.VITE_CLAIMS_ORCHESTRATOR_URI}${path}`
    )

    if (requestOptions.queryParams) {
      const queryParams = requestOptions.queryParams
      Object.entries(queryParams).forEach(([key, value]) => {
        url.searchParams.set(key, value)
      })
    }

    const preparedRequestOptions = await this.prepareRequestOptions()

    const res = await fetch(url.toString(), {
      method: 'GET',
      ...preparedRequestOptions,
    })

    const jsonResponse = await res.json()

    if (!res.ok) {
      throw new Error(`${jsonResponse.title}: ${jsonResponse.detail}`, {
        cause: jsonResponse,
      })
    }

    return jsonResponse
  }

  async post(
    operation: AvailableOperations,
    requestOptions: ApiGetOptions = {}
  ) {
    const operationItem = this.getOperation(operation)

    if (!operationItem) {
      throw new Error('Unknown operation')
    }

    let path = operationItem.url
    if (requestOptions.pathOptions) {
      const pathOptions = requestOptions.pathOptions
      const pathKeys = Object.keys(pathOptions)
      pathKeys.forEach((key: string) => {
        path = path.replace(`\${${key}}`, pathOptions[key])
      })
    }

    const url = new URL(
      `${import.meta.env.VITE_CLAIMS_ORCHESTRATOR_URI}${path}`
    )

    if (requestOptions.queryParams) {
      const queryParams = requestOptions.queryParams
      Object.entries(queryParams).forEach(([key, value]) => {
        url.searchParams.set(key, value)
      })
    }

    const preparedRequestOptions = await this.prepareRequestOptions({
      'Content-Type': 'application/json',
    })

    const res = await fetch(url.toString(), {
      method: 'POST',
      ...preparedRequestOptions,
      body: JSON.stringify(requestOptions.body),
    })

    const jsonResponse = await res.json()

    if (!res.ok) {
      throw new Error(`${jsonResponse.title}: ${jsonResponse.detail}`)
    }
    return jsonResponse
  }

  async patch(
    operation: AvailableOperations,
    requestOptions: ApiGetOptions = {}
  ) {
    const operationItem = this.getOperation(operation)

    if (!operationItem) {
      throw new Error('Unknown operation')
    }

    let path = operationItem.url
    if (requestOptions.pathOptions) {
      const pathOptions = requestOptions.pathOptions
      const pathKeys = Object.keys(pathOptions)
      pathKeys.forEach((key: string) => {
        path = path.replace(`\${${key}}`, pathOptions[key])
      })
    }

    const url = new URL(
      `${import.meta.env.VITE_CLAIMS_ORCHESTRATOR_URI}${path}`
    )

    if (requestOptions.queryParams) {
      const queryParams = requestOptions.queryParams
      Object.entries(queryParams).forEach(([key, value]) => {
        url.searchParams.set(key, value)
      })
    }

    const preparedRequestOptions = await this.prepareRequestOptions({
      'Content-Type': 'application/json',
    })

    const res = await fetch(url.toString(), {
      method: 'PATCH',
      ...preparedRequestOptions,
      body: JSON.stringify(requestOptions.body),
    })
    return res.json()
  }
}

const operations: Record<AvailableOperations, Operation[]> = {
  GET_CALCULATIONS: [
    {
      url: '/v2/calculations?case=${claim_id}',
      user_type: 'staff',
    },
    {
      url: '/vet-practices/${vet_practice_id}/claims/${claim_id}/calculations',
      user_type: 'vet',
    },
  ],
  GET_CLAIMS: [
    {
      url: '/v3/claims',
      user_type: 'staff',
    },
    {
      url: '/vet-practices/${vet_practice_id}/claims',
      user_type: 'vet',
    },
  ],
  POST_CLAIMS: [
    {
      url: '/v3/claims',
      user_type: 'staff',
    },
    {
      url: '/vet-practices/${vet_practice_id}/claims',
      user_type: 'vet',
    },
  ],
  GET_CLAIM: [
    {
      url: '/v3/claims/${claim_id}',
      user_type: 'staff',
    },
    {
      url: '/vet-practices/${vet_practice_id}/claims/${claim_id}',
      user_type: 'vet',
    },
  ],
  GET_DOCUMENT_REQUEST: [
    {
      url: '/document-requests/${document_request_id}',
      user_type: 'staff',
    },
    {
      url: '/document-requests/${document_request_id}',
      user_type: 'vet',
    },
    {
      url: '/document-requests/${document_request_id}',
      user_type: 'unauthenticated',
    },
  ],
  POST_DOCUMENT_REQUEST_UPLOAD_URL: [
    {
      url: '/document-requests/${document_request_id}/make-upload-url',
      user_type: 'vet',
    },
    {
      url: '/document-requests/${document_request_id}/make-upload-url',
      user_type: 'unauthenticated',
    },
  ],
  POST_DOCUMENT_REQUEST_FULFIL: [
    {
      url: '/document-requests/${document_request_id}/fulfil',
      user_type: 'vet',
    },
    {
      url: '/document-requests/${document_request_id}/fulfil',
      user_type: 'unauthenticated',
    },
  ],
  POST_DOCUMENT_REQUEST_PRESIGNED_URL: [
    {
      url: '${presigned_url}',
      user_type: 'vet',
    },
    {
      url: '${presigned_url}',
      user_type: 'unauthenticated',
    },
  ],
  POST_DOCUMENT_UPLOAD_URL: [
    {
      url: '/documents/make-upload-url',
      user_type: 'vet',
    },
  ],
  GET_DOCUMENT_REQUESTS: [
    {
      url: '/vet-practices/${vet_practice_id}/document-requests',
      user_type: 'vet',
    },
  ],
  GET_DOCUMENT_REQUESTS_FOR_CLAIM: [
    {
      url: '/vet-practices/${vet_practice_id}/claims/${claim_id}/document-requests',
      user_type: 'vet',
    },
  ],
  GET_DOCUMENT_REQUESTS_FOR_OWNER: [
    {
      url: '/document-requests',
      user_type: 'staff',
    },
  ],
  GET_POLICY_V2: [
    {
      url: '/v2/policies/${uuid}',
      user_type: 'staff',
    },
    {
      url: '/v2/policies/${uuid}',
      user_type: 'vet',
    },
  ],
  GET_POLICIES_V2: [
    {
      url: '/v2/policies',
      user_type: 'staff',
    },
    {
      url: '/v2/policies',
      user_type: 'vet',
    },
  ],
  GET_POLICIES: [
    {
      url: '/policies',
      user_type: 'staff',
    },
    {
      url: '/policies',
      user_type: 'vet',
    },
  ],
  GET_VET_PRACTICES: [
    {
      url: '/vet-practices',
      user_type: 'vet',
    },
  ],
  GET_VET_PRACTICES_SEARCH: [
    {
      url: '/vet-practices/search',
      user_type: 'staff',
    },
    {
      url: '/vet-practices/search',
      user_type: 'vet',
    },
  ],
  GET_POLICY_LIMITS: [
    {
      url: '/v2/limits/${policyId}',
      user_type: 'staff',
    },
    {
      url: '/v2/limits/${policyId}',
      user_type: 'vet',
    },
  ],
  GET_VET_USER: [
    {
      url: '/my-vet-user',
      user_type: 'vet',
    },
  ],
  PATCH_VET_USER: [
    {
      url: '/my-vet-user',
      user_type: 'vet',
    },
  ],
  VET_USER_TRIGGER_PASSWORD_CHANGE: [
    {
      url: '/my-vet-user/send-change-password-email',
      user_type: 'vet',
    },
  ],
}
