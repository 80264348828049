<template>
  <MApp>
    <template #header>
      <MessageStrip v-if="route.path === '/hub'" />
      <SiteHeader />
    </template>
    <main>
      <MPageMain>
        <Transition name="page" appear mode="out-in">
          <LoadingProgress v-if="!showView" />
          <div v-else :key="route.path">
            <router-view />
          </div>
        </Transition>
      </MPageMain>
      <MPopoverNotificationContainer @dismiss="dismissNotification" />
      <MModalContainer />
      <MLazyZendesk brand="manypets" />
    </main>
  </MApp>
</template>

<script>
import {
  MModalContainer,
  MLazyZendesk,
  MPopoverNotificationContainer,
  MPageMain,
  MApp,
} from '@boughtbymany/many-patterns'
import { setTag, setUser } from '@sentry/vue'
import dayjs from 'dayjs'
import { watch } from 'vue'
import { provide } from 'vue'
import { useRoute } from 'vue-router'

import SiteHeader from '@/components/landmarks/SiteHeader/SiteHeader'
import LoadingProgress from '@/components/loading/LoadingProgress/LoadingProgress.vue'
import MessageStrip from '@/components/templates/MessageStrip.vue'
import { useAuth0 } from '@/composables/useAuth0'
import { useVersionNotification } from '@/composables/useVersionNotification'
import { clarityIdentify } from '@/helpers/tracking'
import { heapTrack } from '@/helpers/tracking'
import { useAuth } from '@/store/auth'

import useLaunchDarkly from './composables/useLaunchDarkly'

export default {
  name: 'App',
  components: {
    MApp,
    MPageMain,
    MModalContainer,
    MLazyZendesk,
    MPopoverNotificationContainer,
    MessageStrip,
    SiteHeader,
    LoadingProgress,
  },
  setup() {
    const route = useRoute()

    const { currentVersion, dismissNotification } = useVersionNotification()

    provide('currentAppVersion', currentVersion)

    return {
      route,
      launchDarklyClient: null,
      auth: useAuth(),
      auth0: useAuth0(),
      dismissNotification,
      currentVersion,
    }
  },
  head() {
    return {
      title: 'Vet portal',
      titleTemplate: '%s | ManyPets Vet Portal',
      link: [{ rel: 'canonical', href: this.canonicalUrl }],
    }
  },
  computed: {
    canonicalUrl() {
      return `https://www.manyvets.com${this.route.path}`
    },
    unregisterServiceWorker() {
      const today = dayjs()
      const expiryDate = dayjs('2023-03-10')
      return today.isBefore(expiryDate)
    },
    showView() {
      // Try and show the view if
      // - the route is logout callback. We need to show the view to forward the user to login
      // - Auth has finished loading and either:
      // - the user is an admin so we don't need a practice to be loaded
      // - the user is not an admin and a practice has been loaded
      // - we don't have a user so we are currently logging in or out
      return (
        !this.auth0.loading.value &&
        (this.isAdmin || this.auth.currentPractice || !this.auth0.user.value)
      )
    },
    isAdmin() {
      if (!this.auth0.user.value) {
        return false
      }
      return ['Handler', 'SysAdmin'].includes(this.auth0.getUserRole()) || false
    },
  },
  async created() {
    if (!this.auth0.loading.value) {
      this.setupUser()
    }
    watch(this.auth0.loading, (loading) => {
      if (loading === false) {
        this.setupUser()
      }
    })
  },
  mounted() {
    window.addEventListener('visibilitychange', this.handleVisibilityChange)

    if (import.meta.env.VITE_HEAP_ID && window.heap?.load) {
      // Loaded here so we don't have to enable inline scripts (bad CSP practice).
      window.heap.load(import.meta.env.VITE_HEAP_ID)

      window.heap.addEventProperties({
        app_version: this.currentVersion,
      })

      let index = 1

      const heapInterval = setInterval(() => {
        if (window.heap?.track && this.auth0.hubUser.value) {
          clearInterval(heapInterval)
          heapTrack({ event: 'User loaded' })
        } else if (index > 5) {
          clearInterval(heapInterval)
        }
        index++
      }, 1000)
    }

    if ('serviceWorker' in navigator && this.unregisterServiceWorker) {
      // Program unregister service workers in our user's browser, so that they don't have to
      // manually clear their own cache. This helps them get past the PWA blank screen issue.
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        if (!registrations.length) {
          console.error('No serviceWorker registrations found.')
          return
        }
        for (const registration of registrations) {
          registration.unregister().then(function (boolean) {
            // eslint-disable-next-line no-console
            console.log(
              boolean ? 'Successfully unregistered' : 'Failed to unregister',
              'ServiceWorkerRegistration\n' +
                (registration.installing
                  ? '  .installing.scriptURL = ' +
                    registration.installing.scriptURL +
                    '\n'
                  : '') +
                (registration.waiting
                  ? '  .waiting.scriptURL = ' +
                    registration.waiting.scriptURL +
                    '\n'
                  : '') +
                (registration.active
                  ? '  .active.scriptURL = ' +
                    registration.active.scriptURL +
                    '\n'
                  : '') +
                '  .scope: ' +
                registration.scope +
                '\n'
            )
          })
        }
      })
    }
  },
  unmounted() {
    window.removeEventListener('visibilitychange', this.handleVisibilityChange)
  },
  methods: {
    /**
     * Listen to page visibility changes and check user auth when coming back to the page/browser tab.
     *
     * Vet practices sometimes have their claims managed centrally, and one user may be logging in
     * and out of multiple practices to raise claims. This code helps to make sure they are always
     * viewing and raising claims for the right practice.
     *
     * Check that it’s working:
     * - Log in on one tab and go to a page that is not the home url.
     * - Open a new tab and go to vet portal.
     * - Log out and log in as a different vet on the second tab.
     * - Go back to the first tab and you should see the tab reload to show the second vets state
     *   rather than the first, and the first tab be back on the home url.
     * - You’ll be able to tell which vet is logged in by the practice name at the top right of the screen
     */
    handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        this.auth0.checkUserAuth()
      }
    },
    setupUser() {
      const hubUser = this.auth0.hubUser.value
      if (this.auth.currentPracticeId) {
        setTag('vet_practice_id', this.auth.currentPracticeId)
        clarityIdentify(this.auth.currentPracticeId)
      }
      if (hubUser) {
        setUser({ id: hubUser.id })

        this.launchDarklyClient = useLaunchDarkly({
          currentUser: hubUser,
          currentPractice: this.auth.currentPracticeId,
          mainVetPractice: this.auth.mainPracticeId,
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/site.scss';
</style>
