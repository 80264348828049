import { HubUser } from '@/domain/vetPortal/HubUser'
import * as mutations from '@/graphql/mutations'
import * as queries from '@/graphql/queries'
import { apiRequest, apiRequestAll } from '@/lib/api'

export const searchHubUsers = async ({
  limit = 1000,
  userId = '',
  verifiedEmail = '',
  company = '',
  userType = '',
  search,
  sort = { direction: 'asc', field: 'first_name' },
  roles = [],
  all = false,
  nextToken = '',
}: {
  limit?: number
  userId?: string
  verifiedEmail?: string
  company?: string | string[]
  userType?: string
  search?: string
  sort?: {
    field: string
    direction: 'asc' | 'desc'
  }
  nextToken?: string
  roles?: Array<string | null>
  all?: boolean
} = {}) => {
  const variables = {} as { [key: string]: unknown }
  const filters = []

  if (limit) {
    variables.limit = limit
  }

  if (userId) {
    filters.push({
      id: {
        eq: userId,
      },
    })
  }

  if (search) {
    const searchKeys = ['id', 'first_name', 'last_name', 'verified_email']
    filters.push({
      or: searchKeys
        .map((key) => [
          {
            [`${key}`]: {
              matchPhrase: search,
            },
          },
          {
            [`${key}`]: {
              wildcard: `*${search}*`,
            },
          },
        ])
        .flat(),
    })
  }

  if (verifiedEmail) {
    filters.push({
      verified_email: {
        eq: verifiedEmail,
      },
    })
  }

  if (Array.isArray(company)) {
    filters.push({
      or: company.map((item) => ({
        company: {
          eq: item,
        },
      })),
    })
  } else if (company) {
    filters.push({
      company: {
        eq: company,
      },
    })
  }

  if (userType) {
    filters.push({
      user_type: { match: userType },
    })
  }

  if (roles.length) {
    filters.push({
      or: roles.map((role) => ({
        role: role === null ? { exists: false } : { eq: role },
      })),
    })
  }

  if (nextToken) {
    variables.nextToken = nextToken
  }

  if (filters.length) {
    variables.filter = {
      and: [...filters],
    }
  }

  if (sort) {
    variables.sort = sort
  }

  const requestFunction = all ? apiRequestAll : apiRequest

  const response = await requestFunction({
    query: queries.searchHubUsers,
    variables,
  })

  if (all) {
    return { items: response as HubUser[], nextToken: '' }
  }

  const result = response as {
    data: { searchHubUsers: { items: HubUser[]; nextToken: string } }
  }
  return result.data?.searchHubUsers || {}
}

export const getHubUser = async (id: string) => {
  if (!id) {
    return {}
  }

  const variables = {
    id,
  }

  const response = await apiRequest({
    query: queries.getHubUsers,
    variables,
  })

  return response.data?.getHubUsers || {}
}

export const createHubUser = async ({
  firstName,
  lastName,
  mobile,
  jobTitle = '',
  email,
  userType,
  companyId = [''],
  role,
  password = '',
  auth0Sub,
  vetPracticeListId = '',
}: {
  firstName: string
  lastName: string
  mobile: string
  jobTitle?: string
  email: string
  userType: string[]
  companyId?: string[]
  role: string
  password?: string
  auth0Sub?: string
  vetPracticeListId?: string
}) => {
  const isUnqiue = await isHubUserEmailUnique(email)

  if (!isUnqiue) {
    throw new Error('This email is already in use.')
  }

  if (role === 'PracticeManager' && !vetPracticeListId) {
    throw new Error('Missing vet practice id for new vet user')
  }

  return apiRequest({
    query: mutations.createHubUsers,
    variables: {
      input: {
        first_name: firstName,
        last_name: lastName,
        mobile: mobile,
        job_title: jobTitle,
        verified_email: email,
        password,
        user_type: userType,
        company: companyId,
        role,
        auth0_sub: auth0Sub,
        vet_practice_list_id: vetPracticeListId,
      },
    },
  })
}

export const updateHubUser = async ({
  id,
  firstName,
  lastName,
  mobile,
  jobTitle,
  email,
  role,
  password,
}: {
  id: string
  firstName: string
  lastName: string
  mobile: string
  jobTitle: string
  email: string
  role: string | null
  password?: string
}) => {
  const variables = {
    id,
    first_name: firstName,
    last_name: lastName,
    mobile: mobile,
    job_title: jobTitle,
    verified_email: email,
    role: role,
  } as {
    [key: string]: unknown
  }

  const { verified_email } = await getHubUser(id)

  if (verified_email !== email) {
    const isUnqiue = await isHubUserEmailUnique(email)

    if (!isUnqiue) {
      throw new Error('This email is already in use.')
    }
  }

  if (password) {
    variables.password = password
  }

  return apiRequest({
    query: mutations.updateHubUsers,
    variables: {
      input: variables,
    },
  })
}

export const updateHubUserVetPracticeId = async (
  id: string,
  newVetPracticeId: string
) => {
  if (!id) {
    throw new Error('No vet user identify has been provided')
  }

  if (!newVetPracticeId) {
    throw new Error('No new vet practice list id has been provided')
  }

  const variables = {
    id,
    vet_practice_list_id: newVetPracticeId,
  } as {
    [key: string]: unknown
  }

  return apiRequest({
    query: mutations.updateHubUsers,
    variables: {
      input: variables,
    },
  })
}

/**
 *
 * @param {string} auth0Sub The auth0 subject of the user
 * @returns object
 */
export const getHubUsersByAuth0Sub = async (auth0Sub: string) => {
  const variables = { auth0_sub: auth0Sub }

  const response = await apiRequest({
    query: queries.getHubUserByAuth0Sub,
    variables,
  })

  return response.data?.getHubUserByAuth0Sub || { items: [] }
}

/**
 *
 * @param {string} email The email address of the user to check
 * @param {string} vetPracticeId The current practice id
 * @returns boolean
 */
export const isHubUserEmailUnique = async (
  email: string,
  vetPracticeId?: string
) => {
  const UNIQUE_EMAIL_QUERY = /* GraphQL */ `
    query SearchHubUsers($email: String!, $nextToken: String) {
      searchHubUsers(
        filter: { verified_email: { eq: $email } }
        limit: 1000
        nextToken: $nextToken
      ) {
        items {
          id
          vet_practice_list_id
        }
        nextToken
      }
    }
  `

  const response = await apiRequestAll({
    query: UNIQUE_EMAIL_QUERY,
    variables: {
      email,
    },
  })

  const result = response as HubUser[]
  //Filter out users within the same practice, if email is the same as the current practice is ok to change as is probably the same as the practice's user
  if (vetPracticeId) {
    return !result.filter(
      (practice) => practice.vet_practice_list_id !== vetPracticeId
    ).length
  }
  return !result?.length
}

/**
 *  Get Vet practice users by Vet practice Id
 * @param {string} vetPracticeId The vet pradctice Id of the vet
 * @returns object
 */
export const getHubUsersByVetPracticeId = async (
  vetPracticeId: string
): Promise<{
  items: HubUser[]
}> => {
  const variables = { vet_practice_list_id: vetPracticeId }

  const response = await apiRequest({
    query: queries.getHubUsersByVetPracticeId,
    variables,
  })

  return response.data?.getHubUsersByVetPracticeId || { items: [] }
}
