import dayjs from 'dayjs'

import {
  PetV2,
  PolicyLimitsCoverage,
  PolicyLimitsSubCoverage,
  PolicyV2,
} from '@/domain/pio/Policy'
import { formatCurrency } from '@/lib/format'
import { getPoliciesV2, getPolicyLimitsV2 } from '@/services/pio/policyService'

export const formatPolicyNumber = ({
  policyNumber,
  prependIBSuiteId,
}: {
  policyNumber: string
  prependIBSuiteId: boolean
}) => {
  const trimmedPolicyNumber = policyNumber.trim()
  let result = trimmedPolicyNumber.replaceAll(/[^A-Za-z0-9]/g, '').toLowerCase()
  if (
    trimmedPolicyNumber.match(/^\d+$/) &&
    trimmedPolicyNumber.length < 10 &&
    prependIBSuiteId
  ) {
    // TODO: We should be ensuring that the trimmedPolicyNumber is 9 characters in length with prepended zeros
    result = 1031268 + trimmedPolicyNumber
  }

  if (trimmedPolicyNumber.match(/^\d{3}-\d{3}-\d{3}$/) && prependIBSuiteId) {
    result = 1031268 + result
  }

  return result
}

export const getPolicyLimitsForPet = async (pet: PetV2, date: string) => {
  const point_in_time = dayjs
    .utc(date || undefined)
    .endOf('day')
    .toISOString()

  const limits = await getPolicyLimitsV2(pet.policy_id, {
    point_in_time,
  })
  const petCoverages = limits.coverage_limits.find(
    (petLimits) => petLimits.insured_entity === pet.uuid
  )
  if (!petCoverages) {
    throw Error('No coverages found on policy for pet')
  }

  return petCoverages
}

export const calculateCoverageBalance = (coverage: PolicyLimitsCoverage) => {
  return (
    (coverage.policy_limit -
      coverage.amount_spent -
      coverage.amount_in_progress) /
    100
  )
}

export const calculateSubcoverageBalance = (
  coverage: PolicyLimitsSubCoverage
) => {
  return coverage.limit_amount
    ? (coverage.limit_amount -
        coverage.amount_spent -
        coverage.amount_in_progress) /
        100
    : 0
}

export const getVetFeesLimit = (policy: PolicyV2) => {
  if (policy?.product_line === 'cat-dog') {
    return formatCurrency(policy.coverages['vet-fees'].limit)
  }

  return formatCurrency(policy.coverages.vet_fees.limit / 100)
}

export const findPolicies = async ({
  field,
  type,
}: {
  field: string
  type: 'policyNumber' | 'email'
}): Promise<{
  items: PolicyV2[]
  meta: Record<string, any>
}> => {
  if (type === 'email') {
    return await getPoliciesV2({ policy_holder_email: field })
  }

  return await getPoliciesV2ForRef(field)
}

const getPoliciesV2ForRef = async (ref: string) => {
  let policies
  const formattedRef = formatPolicyNumber({
    policyNumber: ref,
    prependIBSuiteId: true,
  })

  policies = await getPoliciesV2({
    ref: formatPolicyNumber({
      policyNumber: ref,
      prependIBSuiteId: false,
    }),
  })

  // Try again with IB Suite ID
  if (
    (!policies.items || policies.items.length === 0) &&
    formattedRef !== ref
  ) {
    policies = await getPoliciesV2({ ref: formattedRef })
  }

  return policies
}

export const getCover = (
  policy: PolicyV2,
  petUuid: string,
  claim_start_date?: any,
  claim_end_date?: any
) => {
  return policy.pets
    .map((pet) => {
      return {
        excess: formatCurrency(pet?.cover.excesses.excess),
        copay: pet?.cover.excesses.co_payment,
        bbm_insured_entity_uuid: pet.uuid,
        limit: getVetFeesLimit(policy),
        ...(typeof claim_start_date === 'string' && {
          claim_start_date,
        }),
        ...(typeof claim_end_date === 'string' && {
          claim_end_date,
        }),
      }
    })
    .find((pet) => pet.bbm_insured_entity_uuid === petUuid)
}

export const getPolicyDetails = async (
  policy: PolicyV2,
  petUuid: string,
  claim_start_date?: any,
  claim_end_date?: any
) => {
  const pet = policy.pets.find((pet: any) => pet.uuid === petUuid)

  if (!pet) {
    throw new Error('Pet not found on policy')
  }

  const limits = await getPolicyLimitsForPet(pet, claim_start_date)

  const customer = policy.customers[0]
  const cover = getCover(policy, petUuid, claim_start_date, claim_end_date)

  return { customer, cover, limits, pet }
}
